<template>
  <a-row class="content-fix" type="flex">
    <!-- Spinner for loading -->
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1" style="padding-top: 30px">
      <span slot="tab"><a-icon type="project" />Создать</span>

      <!-- Language Tabs -->
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="4" class="form_title">Язык:</a-col>
        <a-col :span="12">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>

      <!-- Dynamic Form Fields -->
      <a-row
        align="middle"
        :class="{ 'form-group--error': errors[field.key] }"
        class="form-group flex-baseline"
        v-for="field in filteredFields"
        :key="field.key"
      >
        <a-col :span="4" class="form_title">
          <label>{{ $t(field.label) }}</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-if="field.type === 'text'"
            v-model="form[field.key]"
            :placeholder="$t(field.placeholder)"
            :readonly="field.key === 'title' || field.key === 'last_update'"
            @input="validateLength(field.key, field.minLength, field.maxLength)"
          />
          <a-input
            v-else-if="field.type === 'date'"
            type="date"
            v-model="form[field.key]"
            :placeholder="$t(field.placeholder)"
            class="input-date"
          />
          <a-select
            v-else-if="field.type === 'select'"
            v-model="form[field.key]"
            style="width: 100%"
          >
            <a-select-option
              v-for="option in field.options"
              :key="option.value"
              :value="option.value"
            >
              {{ $t(option.label) }}
            </a-select-option>
          </a-select>
          <a-switch
            v-else-if="field.type === 'switch'"
            v-model="form[field.key]"
          />
        </a-col>
      </a-row>

      <!-- Action Buttons -->
      <a-row class="form-buttons__action" type="flex">
        <a-col :span="3" class="form_title" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />Отмена
          </a-button>
          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit"
          >
            <a-icon type="check" />
            Создать
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "CreateCertificate",
  data() {
    return {
      langTab: $langPrefix, // Current language tab
      form: {}, // Data to store form values
      fields: [
        {
          key: "title",
          label: "TableTitle",
          type: "text",
          placeholder: "TableTitle",
          isLangSpecific: true // Indicates language-specific fields
        },
        {
          key: "last_update",
          label: "LastUpdateDate",
          type: "text",
          placeholder: "LastUpdateDate",
          isLangSpecific: true
        },
        {
          key: "full_name",
          label: "FormFullName",
          type: "text",
          placeholder: "FormFirstNamePlace",
          minLength: 1,
          maxLength: 255
        },
        {
          key: "passport_number",
          label: "PassportNumber",
          type: "text",
          placeholder: "PassportNumber",
          minLength: 1,
          maxLength: 9
        },
        {
          key: "pinfl",
          label: "pinfl",
          type: "text",
          placeholder: "pinfl",
          minLength: 1,
          maxLength: 14
        },
        {
          key: "gender",
          label: "FormGender",
          type: "select",
          options: [
            { value: 1, label: "FormMale" },
            { value: 2, label: "FormFemale" }
          ]
        },
        {
          key: "cert_number",
          label: "cert_number",
          type: "text",
          placeholder: "cert_number",
          minLength: 1,
          maxLength: 255
        },
        {
          key: "cert_registered_date",
          label: "cert_registered_date",
          type: "date",
          placeholder: "cert_registered_date"
        },
        {
          key: "cert_validity_date",
          label: "cert_validity_date",
          type: "date",
          placeholder: "cert_validity_date"
        },
        {
          key: "licence_number",
          label: "licence_number",
          type: "text",
          placeholder: "licence_number",
          minLength: 1,
          maxLength: 255
        },
        {
          key: "licence_registered_date",
          label: "licence_registered_date",
          type: "date",
          placeholder: "licence_registered_date"
        },
        {
          key: "order",
          label: "Order",
          type: "text",
          placeholder: "Order",
        },
        { key: "is_active", label: "Статус", type: "switch" }
      ],
      errors: {}, // Validation errors
      pending: false,
      loading: false
    }
  },
  computed: {
    filteredFields() {
      // Adjust keys for language-specific fields
      return this.fields.map((field) => {
        if (field.isLangSpecific) {
          // Generate dynamic key for the current language tab
          const dynamicKey = `${field.key}_${this.langTab}`
          if (!(dynamicKey in this.form)) {
            this.$set(this.form, dynamicKey, "") // Ensure reactivity for missing keys
          }
          return { ...field, key: dynamicKey }
        }
        return field
      })
    }
  },
  methods: {
    validateLength(key, minlength, maxlength) {
      const value = this.form[key]
      if (value.length > maxlength) {
        this.form[key] = value.substring(0, maxlength) // Trim to maxlength
      }
      if (value.length < minlength) {
        this.errors[key] = `Minimum length is ${minlength} characters.`
      } else {
        this.errors[key] = null // Clear error if valid
      }
    },
    async fetchCertificateTableData() {
      this.loading = true // Show loader while fetching data
      try {
        const response = await this.$api.get("/admin/common/certificate/table/")
        if (response.status === 200) {
          const data = response.data // Retrieve API response data

          // Dynamically populate form fields based on the response
          Object.keys(data).forEach((key) => {
            // If the key matches a dynamic field in `form`, assign the value
            // if (this.form.hasOwnProperty(key)) {

            this.form[key] = data[key] || "" // Populate only matching fields
            // }
          })
        } else {
          console.error("Failed to fetch certificate table data:", response)
        }
      } catch (error) {
        console.error("Error fetching certificate table data:", error)
      } finally {
        this.loading = false // Hide loader after fetching data
      }
    },

    async submit() {
      if (this.pending) return

      this.pending = true

      try {
        let f = new FormData()

        // Append all fields to FormData
        Object.keys(this.form).forEach((key) => {
          const value = this.form[key]
          if (value !== null && value !== undefined) {
            f.append(key, value)
          }
        })

        const res = await this.$api.post(`/admin/common/certificate/create/`, f)

        if (res.status >= 200 && res.status < 300) {
          this.$router.go(-1) // Navigate back on success
        } else {
          console.error("Error creating certificate:", res)
        }
      } catch (error) {
        console.error("Submit Error:", error)
      } finally {
        this.pending = false // Reset pending state
      }
    }
  },
  created() {
    // Pre-initialize the form object with all necessary keys
    this.fields.forEach((field) => {
      if (field.isLangSpecific) {
        const languages = ["uz", "ru", "en", "qr"]
        languages.forEach((lang) => {
          const key = `${field.key}_${lang}`
          this.$set(this.form, key, "") // Ensure all language-specific keys are reactive
        })
      } else {
        this.$set(this.form, field.key, field.type === "switch" ? false : "")
      }
    })

    // Fetch certificate data
    this.fetchCertificateTableData()
  }
}
</script>

<style scoped>
.content-fix {
  /* background: #f5f5f5; */
  padding: 20px;
  border-radius: 8px;
}

.form_title {
  font-weight: bold;
  margin-bottom: 8px;
}

.input-date {
  width: 100%;
  max-width: 300px;
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group--error .form_title {
  color: red;
}

.form-buttons__action {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
